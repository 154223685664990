import React from "react"
import Layout from "../components/Layout"
import { FliesText, SplitColorChannelText, LiquidDistortionText  } from 'react-text-fun';


export default () => (
  <Layout>
    <section className="fourohfour">
      <div className="container">
        <div className="col-12">
          <div className="row">
            <div className="d-flex align-items-center justify-content-center flex-wrap flex-column w-100">

              <div className="fourohfour-wrap">
                <div className="ab">
                <SplitColorChannelText
                  text="404"
                  fontSize={220}
                  rotation={0.02}
                  rgbOffset={0.05}
                  addBlur={true}
                  addNoise={false}
                  fontFamily="ImportedRalewayBlack, sans-serif"
                />
                </div>

                <FliesText
                  cellRadius={0.69}
                  text="404"
                  fontSize={220}
                  cellWidth={0.02}
                  speed={2}
                  dodge={true}
                  dodgeY={0.5}
                  dodgeSpread={0.19}
                  fill="#000000"
                  fontFamily="ImportedRalewayBlack, sans-serif"
                />
            </div>

              <div class="medium-text-block m-0 mb-5">
                  <div class="medium-text-wrap text-center p-5">
                      <h1 className="m-0">PAGE NOT FOUND</h1>
                  </div>
              </div>
            <p className="text-center w-50">Looks like you are trying to find something that does not exist. Please try the main menu or go back to the <a href="/">homepage</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
